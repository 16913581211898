import { IncomingIntegrationId, ProjectIntegration } from "../../types/models/IncomingIntegraions";
import { getSelectedProject } from "../projects/selectors";
import { AppState } from "../reducers";

export const getIntegrations = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.integrations
);

export const getIntegrationById = (id: IncomingIntegrationId) => ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.integrations.find(item => item.id === id)
);

export const getIntegrationsLoading = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.integrationsLoading
);

export const getIntegrationToEdit = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.integrationToEdit
);

export const getIntegrationToEditId = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.integrationToEdit?.id
);

export const getActions = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.actions
);

export const getActionsLoading = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.actionsLoading
);

export const getActionToEdit = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.actionToEdit
);

export const getActionToEditId = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.actionToEdit?.id
);

export const getCallbacks = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.callbacks
);

export const getCallbacksLoading = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.callbacksLoading
);

export const getCallbackToEdit = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.callbackToEdit
);

export const getLinkedProjects = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.linkedProjects
);

export const getLinkedProjectsForIntegration = (
  id: IncomingIntegrationId,
) => ({ incomingIntegrations }: AppState): ProjectIntegration[] | undefined => (
  incomingIntegrations.linkedProjects?.[id]
);

export const getLinkedProjectsLoading = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.linkedProjectsLoading
);

export const checkIfCurrentProjectLinked = (id: IncomingIntegrationId) => (state: AppState) => {
  const selectedProject = getSelectedProject(state);
  const integrationProjects = getLinkedProjectsForIntegration(id)(state);

  if (!selectedProject || !integrationProjects?.length) return false;

  return integrationProjects.some(({ projectId }) => projectId === selectedProject.id);
};

export const getLinkData = ({ incomingIntegrations }: AppState) => (
  incomingIntegrations.linkToEdit
);
