import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { block } from "bem-cn";
import { useTranslation } from 'react-i18next';

import { generateId } from '../../../../services/helpers/generateId';

import './styles.scss';

const b = block("feature-examples");

type FeatureExamplesProps = {
  examples: string[],
  disabled?: boolean,
  onChange: (examples: string[]) => void,
}

export default function FeatureExamples({
  examples = [],
  disabled = false,
  onChange,
}: FeatureExamplesProps): ReactElement {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');

  const submitValue = useCallback(
    (value: string) => {
      const word = value.trim();

      setInputValue("");
      if (!word) return;
      if (examples.includes(word)) return;

      onChange([...examples, word]);
    },
    [examples, onChange],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputText = event.target.value;
      const isLastSymbolSpace = /\s$/.test(inputText);
      if (isLastSymbolSpace) {
        submitValue(inputText);
      } else {
        setInputValue(event.target.value);
      }
    },
    [submitValue],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const inputText = (event.target as HTMLInputElement).value;
        submitValue(inputText);
      }

      if (
        (event.target as HTMLInputElement).value === "" &&
        event.key === "Backspace"
      ) {
        onChange(examples.slice(0, -1));
      }
    },
    [examples, onChange, submitValue],
  );

  const handleDeleteExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const example = (event.target as HTMLButtonElement).name;

      onChange(examples.filter(e => e !== example));
    },
    [examples, onChange],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const inputText = event.target.value;
      submitValue(inputText);
    },
    [submitValue],
  );

  const id = useMemo(() => generateId(), []);

  useEffect(
    () => {
      if (!inputRef.current) return;

      inputRef.current.style.width = (Math.max(2, inputValue.length) * 1.2) + 'ch';
    },
    [inputValue],
  );

  useEffect(
    () => {
      setInputValue("");
    },
    [examples],
  );

  return (
    <label htmlFor={id} className={b({ disabled })}>
      {examples.map(e => (
        <div key={e} className={b("example")}>
          {e}
          <button
            name={e}
            className={b("cross-icon")}
            onClick={handleDeleteExampleClick}
          >
            &#x2715;
          </button>
        </div>
      ))}
      {!disabled && examples.length === 0 && (
        <span className={b("placeholder")}>{t("COMMON.EXAMPLES")}</span>
      )}
      <input
        id={id}
        ref={inputRef}
        disabled={disabled}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </label>
  );
}
