import { createAsyncAction, createAction } from "typesafe-actions";

import {
  IncomingIntegration,
  IncomingIntegrationAction,
  IncomingIntegrationCallback,
  ProjectIntegration,
} from "../../types/models/IncomingIntegraions";

import {
  DeleteActionRequest,
  DeleteCallbackRequest,
  DeleteIntegrationRequest,
  GetActionsRequest,
  GetCallbacksRequest,
  GetLinkedProjectsRequest,
  PostActionRequest,
  PostCallbackRequest,
  UnlinkIntegrationFromProjectsRequest,
} from "../../types/requests/IncomingIntegraions";

import { ErrorAction } from "../reducers";

// Integration
export const loadIntegrations = createAsyncAction(
  'incomingIntegrations/loadIntegrations/requested',
  'incomingIntegrations/loadIntegrations/succeeded',
  'incomingIntegrations/loadIntegrations/failed',
)<undefined, IncomingIntegration[], ErrorAction>();

export const setIntegrationToEdit = createAction('incomingIntegrations/setIntegrationToEdit')<IncomingIntegration>();
export const resetIntegrationToEdit = createAction('incomingIntegrations/resetIntegrationToEdit')();

type SaveIntegrationPayload = { close?: boolean, data: IncomingIntegration };
export const saveIntegration = createAsyncAction(
  'incomingIntegrations/saveIntegration/requested',
  'incomingIntegrations/saveIntegration/succeeded',
  'incomingIntegrations/saveIntegration/failed',
)<SaveIntegrationPayload, SaveIntegrationPayload, ErrorAction>();

export const deleteIntegration = createAsyncAction(
  'incomingIntegrations/deleteIntegration/requested',
  'incomingIntegrations/deleteIntegration/succeeded',
  'incomingIntegrations/deleteIntegration/failed',
)<DeleteIntegrationRequest, number, ErrorAction>();

// Actions
export const loadActions = createAsyncAction(
  'incomingIntegrations/loadActions/requested',
  'incomingIntegrations/loadActions/succeeded',
  'incomingIntegrations/loadActions/failed',
)<GetActionsRequest, IncomingIntegrationAction[], ErrorAction>();

export const setActionToEdit = createAction('incomingIntegrations/setActionToEditeditAction')<IncomingIntegrationAction>();
export const resetActionToEdit = createAction('incomingIntegrations/resetActionToEditeditAction')();

type SaveActionPayload = {
  close: boolean,
  data: IncomingIntegrationAction
};

export const saveAction = createAsyncAction(
  'incomingIntegrations/saveAction/requested',
  'incomingIntegrations/saveAction/succeeded',
  'incomingIntegrations/saveAction/failed',
)<
  SaveActionPayload & PostActionRequest['params'],
  SaveActionPayload,
  ErrorAction
>();

export const deleteAction = createAsyncAction(
  'incomingIntegrations/deleteAction/requested',
  'incomingIntegrations/deleteAction/succeeded',
  'incomingIntegrations/deleteAction/failed',
)<DeleteActionRequest, number, ErrorAction>();

// Callbacks
export const loadCallbacks = createAsyncAction(
  'incomingIntegrations/loadCallbacks/requested',
  'incomingIntegrations/loadCallbacks/succeeded',
  'incomingIntegrations/loadCallbacks/failed',
)<GetCallbacksRequest, IncomingIntegrationCallback[], ErrorAction>();

export const setCallbackToEdit = createAction('incomingIntegrations/setCallbackToEdit')<IncomingIntegrationCallback>();
export const resetCallbackToEdit = createAction('incomingIntegrations/resetCallbackToEdit')();

export const saveCallback = createAsyncAction(
  'incomingIntegrations/saveCallback/requested',
  'incomingIntegrations/saveCallback/succeeded',
  'incomingIntegrations/saveCallback/failed',
)<
  PostCallbackRequest['params'] & { data: IncomingIntegrationCallback },
  undefined,
  ErrorAction
>();

export const deleteCallback = createAsyncAction(
  'incomingIntegrations/deleteCallback/requested',
  'incomingIntegrations/deleteCallback/succeeded',
  'incomingIntegrations/deleteCallback/failed',
)<DeleteCallbackRequest, number, ErrorAction>();

export const editLink = createAction('incomingIntegrations/editLink')<ProjectIntegration | null>();

export const getLinkedProjects = createAsyncAction(
  'incomingIntegrations/getLinkedProjects/requested',
  'incomingIntegrations/getLinkedProjects/succeeded',
  'incomingIntegrations/getLinkedProjects/failed',
)<
  GetLinkedProjectsRequest,
  { integrationId: GetLinkedProjectsRequest, data: ProjectIntegration[] },
  ErrorAction
>();

export const linkIntegrationAndProject = createAsyncAction(
  'incomingIntegrations/linkIntegrationAndProject/requested',
  'incomingIntegrations/linkIntegrationAndProject/succeeded',
  'incomingIntegrations/linkIntegrationAndProject/failed',
)<ProjectIntegration, ProjectIntegration, ErrorAction>();

export const unlinkIntegrationFromProject = createAsyncAction(
  'incomingIntegrations/unlinkIntegrationFromProject/requested',
  'incomingIntegrations/unlinkIntegrationFromProject/succeeded',
  'incomingIntegrations/unlinkIntegrationFromProject/failed',
)<UnlinkIntegrationFromProjectsRequest, undefined, ErrorAction>();
