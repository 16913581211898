import { Reducer } from "redux";
import { createReducer, ActionType } from "typesafe-actions";

import {
  IncomingIntegration,
  IncomingIntegrationAction,
  IncomingIntegrationCallback,
  IncomingIntegrationId,
  ProjectIntegration,
} from "../../types/models/IncomingIntegraions";

import * as actions from './actions';

export type State = {
  integrations: IncomingIntegration[];
  integrationsLoading: boolean;
  integrationToEdit: null | IncomingIntegration;
  integrationSaveLoading: boolean;
  actions: null | IncomingIntegrationAction[];
  actionsLoading: boolean;
  actionToEdit: null | IncomingIntegrationAction;
  actionSaveLoading: boolean;
  callbacks: null | IncomingIntegrationCallback[];
  callbacksLoading: boolean;
  callbackToEdit: null | IncomingIntegrationCallback;
  callbackSaveLoading: boolean;
  linkedProjectsLoading: boolean;
  linkedProjects: Record<IncomingIntegrationId, ProjectIntegration[]>;
  linkToEdit: null | ProjectIntegration;
}

const initialActions = {
  actions: null,
  actionsLoading: false,
  actionToEdit: null,
  actionSaveLoading: false,
};

const initialCallbacks = {
  callbacks: null,
  callbacksLoading: false,
  callbackToEdit: null,
  callbackSaveLoading: false,
};

const initialState: State = {
  integrations: [],
  integrationsLoading: false,
  integrationToEdit: null,
  integrationSaveLoading: false,
  linkedProjectsLoading: false,
  linkedProjects: {},
  linkToEdit: null,
  ...initialActions,
  ...initialCallbacks,
};

type R = Reducer<State>;
export const incomingIntegrationsReducer: R = createReducer<State>(initialState)
  .handleAction(
    actions.loadIntegrations.request,
    (state: State): State => ({
      ...state,
      integrationsLoading: true,
    }),
  )
  .handleAction(
    actions.loadIntegrations.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadIntegrations.success>,
    ): State => ({
      ...state,
      integrations: payload,
      integrationsLoading: false,
    }),
  )
  .handleAction(
    actions.loadIntegrations.failure,
    (state: State): State => ({
      ...state,
      integrationsLoading: false,
    }),
  )
  .handleAction(
    actions.setIntegrationToEdit,
    (
      state: State,
      { payload: integrationToEdit }: ActionType<typeof actions.setIntegrationToEdit>,
    ): State => ({
      ...state,
      integrationToEdit,
      ...initialActions,
      ...initialCallbacks,
    }),
  )
  .handleAction(
    actions.resetIntegrationToEdit,
    (state: State): State => ({
      ...state,
      integrationToEdit: null,
      ...initialActions,
      ...initialCallbacks,
    }),
  )
  .handleAction(
    actions.saveIntegration.request,
    (state: State): State => ({
      ...state,
      integrationSaveLoading: true,
    }),
  )
  .handleAction(
    actions.saveIntegration.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.saveIntegration.success>,
    ): State => ({
      ...state,
      integrationSaveLoading: false,
      integrationToEdit: payload.close ? null : payload.data,
      integrations: state.integrations
        .filter(({ id }) => id !== payload.data.id)
        .concat(payload.data),
    }),
  )
  .handleAction(
    actions.saveIntegration.failure,
    (state: State): State => ({
      ...state,
      integrationSaveLoading: false,
    }),
  )
  .handleAction(
    actions.deleteIntegration.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.deleteIntegration.success>,
    ): State => ({
      ...state,
      integrations: state.integrations.filter(({ id }) => id !== payload),
    }),
  )

  // Actions
  //
  .handleAction(
    actions.loadActions.request,
    (state: State): State => ({
      ...state,
      actionsLoading: true,
    }),
  )
  .handleAction(
    actions.loadActions.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadActions.success>,
    ): State => ({
      ...state,
      actions: payload,
      actionsLoading: false,
    }),
  )
  .handleAction(
    actions.loadActions.failure,
    (state: State): State => ({
      ...state,
      actionsLoading: false,
    }),
  )
  .handleAction(
    actions.setActionToEdit,
    (
      state: State,
      { payload: actionToEdit }: ActionType<typeof actions.setActionToEdit>,
    ): State => ({
      ...state,
      actionToEdit,
      ...initialCallbacks,
    }),
  )
  .handleAction(
    actions.resetActionToEdit,
    (state: State): State => ({
      ...state,
      actionToEdit: null,
      ...initialCallbacks,
    }),
  )
  .handleAction(
    actions.saveAction.request,
    (state: State): State => ({
      ...state,
      actionSaveLoading: true,
    }),
  )
  .handleAction(
    actions.saveAction.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.saveAction.success>,
    ): State => ({
      ...state,
      actionSaveLoading: false,
      actionToEdit: payload.close ? null : payload.data,
    }),
  )
  .handleAction(
    actions.saveAction.failure,
    (state: State): State => ({
      ...state,
      actionSaveLoading: false,
    }),
  )
  .handleAction(
    actions.deleteAction.success,
    (
      state: State,
      { payload: id }: ActionType<typeof actions.deleteAction.success>,
    ): State => ({
      ...state,
      actions: (state.actions || []).filter(item => item.id !== id),
    }),
  )

  // Callbacks
  //
  .handleAction(
    actions.loadCallbacks.request,
    (state: State): State => ({
      ...state,
      callbacksLoading: true,
    }),
  )
  .handleAction(
    actions.loadCallbacks.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadCallbacks.success>,
    ): State => ({
      ...state,
      callbacks: payload,
      callbacksLoading: false,
    }),
  )
  .handleAction(
    actions.loadActions.failure,
    (state: State): State => ({
      ...state,
      callbacksLoading: false,
    }),
  )
  .handleAction(
    actions.setCallbackToEdit,
    (
      state: State,
      { payload: callbackToEdit }: ActionType<typeof actions.setCallbackToEdit>,
    ): State => ({
      ...state,
      callbackToEdit,
    }),
  )
  .handleAction(
    actions.resetCallbackToEdit,
    (state: State): State => ({
      ...state,
      callbackToEdit: null,
    }),
  )
  .handleAction(
    actions.saveCallback.request,
    (state: State): State => ({
      ...state,
      callbackSaveLoading: true,
    }),
  )
  .handleAction(
    actions.saveCallback.success,
    (state: State): State => ({
      ...state,
      callbackSaveLoading: false,
    }),
  )
  .handleAction(
    actions.saveCallback.failure,
    (state: State): State => ({
      ...state,
      callbackSaveLoading: false,
    }),
  )
  .handleAction(
    actions.deleteCallback.success,
    (
      state: State,
      { payload: id }: ActionType<typeof actions.deleteCallback.success>,
    ): State => ({
      ...state,
      callbacks: (state.callbacks || []).filter(item => item.id !== id),
    }),
  )
  .handleAction(
    actions.getLinkedProjects.request,
    (state: State): State => ({
      ...state,
      linkedProjectsLoading: true,
    }),
  )
  .handleAction(
    actions.getLinkedProjects.success,
    (
      state: State,
      { payload: { data, integrationId } }: ActionType<typeof actions.getLinkedProjects.success>,
    ): State => ({
      ...state,
      linkedProjectsLoading: false,
      linkedProjects: {
        ...state.linkedProjects,
        [integrationId]: data,
      },
    }),
  )
  .handleAction(
    actions.getLinkedProjects.failure,
    (state: State): State => ({
      ...state,
      linkedProjectsLoading: false,
    }),
  )
  .handleAction(
    actions.linkIntegrationAndProject.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.linkIntegrationAndProject.success>,
    ): State => ({
      ...state,
      linkToEdit: null,
      linkedProjectsLoading: false,
      linkedProjects: {
        ...state.linkedProjects,
        [payload.integrationId]: [payload, ...(state.linkedProjects[payload.integrationId] || [])],
      },
    }),
  )
  .handleAction(
    actions.editLink,
    (
      state: State,
      { payload }: ActionType<typeof actions.editLink>,
    ): State => ({
      ...state,
      linkToEdit: payload,
    }),
  );
