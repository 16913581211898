import { generateId } from "../../services/helpers/generateId";
import { ContentType } from "../backendModels/IncomingIntegraionsBackend";

export type BodyLocation = {
  location: 'body';
  path: string;
}

export type QueryLocation = {
  location: 'query';
  paramName: string;
}

export type HeaderLocation = {
  location: 'header';
  paramName: string;
}

export type KeyToCheck = { id: string, value: string };
export type BodyContainsLocation = {
  location: 'body_contains';
}

export type PredicateLocation = {
  location: string;
}

export type PathLocation = {
  location: 'path';
  index: number;
}

export type IncomingIntegrationAction = {
  id?: number;
  slug: string;
  isIgnored?: boolean;
  requestMapping?: string;
  responseMapping?: string;
  responseContentType?: ContentType;
  isRequestMappingValid?: boolean;
  isResponseMappingValid?: boolean;
}

export type IncomingIntegrationActionsList = {
  actions: IncomingIntegrationAction[]
};

export type IncomingIntegrationCallbacksList = {
  callbacks: IncomingIntegrationCallback[];
}

export type CallbackCondition = 'reply' | 'reply_iterable' | 'tag' | 'close' | 'forward' | 'attachments' | 'attachments_iterable';
export const callbackConditionTypes: CallbackCondition[] = ['reply', 'reply_iterable', 'tag', 'close', 'forward', 'attachments', 'attachments_iterable'];

export type CallbackRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export const callbackRequestMethodTypes: CallbackRequestMethod[] = ['GET', 'POST', 'PUT', 'DELETE'];

export enum IncominIntegrationProtocol {
  http = "http",
}

enum ContentTypes {
  json = 'application/json',
  form = 'application/x-www-form-urlencoded',
}

enum MediaFilesContentTypes {
  formData = 'multipart/form-data',
  binary = 'binary',
}

export const contentTypes = [ContentTypes.json, ContentTypes.form];
export const mediaContentTypes = [MediaFilesContentTypes.formData, MediaFilesContentTypes.binary];

export type IncomingIntegrationCallback = {
  id?: number;
  condition: CallbackCondition | string;
  uri: string;
  isMappingValid?: boolean;
  isPredicateCondition?: boolean;
  requestMethod: CallbackRequestMethod;
  requestMapping?: string;
  authorization?: {
    tvm?: string;
  };
  contentType: ContentTypes | MediaFilesContentTypes;
}

export type TVMAuthData = {
  type: 'tvm';
}

export type APIKeyAuthData = {
  type: 'api_key';
  locationData: LocationData;
}

export type SignatureNotRequiredData = {
  type: 'not_required';
}

export type SignatureRequiredData = {
  type: 'required'
  locationData: LocationData;
}

export type LocationData = BodyLocation | QueryLocation | HeaderLocation | PathLocation;
export const locationDataTypes: LocationData['location'][] = ['body', 'query', 'header', 'path'];

export enum LocationDataTypes {
  body = 'body',
  query = 'query',
  header = 'header',
  path = 'path',
}

export type AuthData = TVMAuthData | APIKeyAuthData;
export const authDataTypes: AuthData['type'][] = ['api_key', 'tvm'];

export enum AuthDataTypes {
  apiKeyIpAddress = 'api_key',
  tvm = 'tvm',
}

export type ActionData = BodyLocation | QueryLocation | PathLocation | BodyContainsLocation | PredicateLocation;
export const actionDataTypes: ActionData['location'][] = ['body', 'query', 'path', 'body_contains', 'predicate'];

export enum ActionDataTypes {
  Body = 'body',
  Query = 'query',
  Path = 'path',
  BodyContains = 'body_contains',
  PredicateLocation = 'predicate',
}

export const getEmptyLocationData = (location?: LocationData['location']): LocationData => {
  switch (location) {
    case LocationDataTypes.path:
      return {
        location,
        index: 0,
      } as PathLocation;
    case LocationDataTypes.query:
    case LocationDataTypes.header:
      return {
        location,
        paramName: '',
      } as QueryLocation | HeaderLocation;
    case LocationDataTypes.body:
    default:
      return {
        location: location || 'body',
        path: '',
      } as BodyLocation;
  }
};

export const getEmptyAuthData = (type?: AuthDataTypes): AuthData => {
  switch (type) {
    case AuthDataTypes.apiKeyIpAddress:
      return {
        type,
        locationData: getEmptyLocationData(),
      };
    case AuthDataTypes.tvm:
      return { type };
    default:
      return { type: 'tvm' };
  }
};

export const getEmptyActionData = (location?: ActionDataTypes): ActionData => {
  switch (location) {
    case ActionDataTypes.Query:
      return {
        location,
        paramName: '',
      } as QueryLocation;

    case ActionDataTypes.Path:
      return {
        location,
        index: 0,
      } as PathLocation;

    case ActionDataTypes.BodyContains:
      return { location };

    case ActionDataTypes.PredicateLocation:
      return { location };

    case ActionDataTypes.Body:
    default:
      return {
        location: ActionDataTypes.Body,
        path: '',
      } as BodyLocation;
  }
};

export const getEmptyIntegration = (): IncomingIntegration => ({
  slug: '',
  signatureData: { type: 'not_required' },
  authData: { type: 'tvm' },
  actionData: {
    location: 'body',
    path: '',
  },
  protocol: IncominIntegrationProtocol.http,
});

export const getEmptyIntegrationAction = (): IncomingIntegrationAction => ({
  slug: '',
  isIgnored: false,
  requestMapping: '',
  responseMapping: '',
});

export const getEmptyIntegrationCallback = (): IncomingIntegrationCallback => ({
  uri: '',
  condition: 'reply',
  requestMethod: 'GET',
  requestMapping: '',
  contentType: ContentTypes.json,
});

export const getEmptyKeyToCheckField = () => ({ id: generateId(), value: '' });

export type AdditionalField = { id: string, value: string };

export const getEmptyAdditionalField = () => ({ id: generateId(), value: '' });

export type IncomingIntegrationId = number;

export type IncomingIntegration = {
  id?: number;
  slug: string;
  signatureData?: SignatureRequiredData | SignatureNotRequiredData;
  authData: AuthData;
  actionData: ActionData;
  additionalFields?: AdditionalField[];
  projectsCount?: number;
  isAsync?: boolean;
  protocol: IncominIntegrationProtocol;
};

// Validation
export type Template = {
  template: string;
};

export type IsValidTemplate = {
  isValidTemplate: boolean;
  message?: string;
};

// Projects
export type AdditionalDataItem = {
  id: string;
  key: string;
  value: string;
}

export const getEmptyAdditionalData = (): AdditionalDataItem => ({
  id: generateId(),
  key: '',
  value: '',
});

export type ProjectIntegration = {
  id?: string;
  integrationId: number;
  integrationSlug?: string;
  projectId: number;
  projectSlug: string;
  projectTitle: string;
  baseUrl?: string;
  callbackAuthHeaders?: string;
  signatureToken?: string;
  additionalData?: AdditionalDataItem[];
}
