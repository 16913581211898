import { dateMscToLocal } from "../../services/helpers/dateUtils";
import { generateId } from "../../services/helpers/generateId";
import { objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { ExtendedDialogBackend, NewExtendedDialogBackend, NewSupportResponseBackend, ResponseButtonsBlockBackend, ResponseExplanationBackend, ResponseMetricBackend, TopicProbabilityBackend } from "../backendModels/DialogBackend";
import { TopicProbability, Dialog, RequestMessage, ResponseMessage, ResponseExplanation, ResponseButtonsBlock, DialogMessage, Attachment, ScenariosWayPoint, Button, ResponseMetric } from "../models/Dialog";
import { MessageAuthor } from "../models/Message";
import { TextType } from "../models/ScenarioGraphAction";

export function parseDialog(dialog: ExtendedDialogBackend): Dialog {
  const messages = dialog.records.reduce<DialogMessage[]>((accum, record) => {
    const { request, response } = record;

    const scenarioPath = response.scenarios_path?.scenarios_path;

    const {
      scenario_id: scenarioId = '',
      scenario_slug: scenarioSlug = '',
      scenario_title: scenarioTitle = '',
    } = scenarioPath?.[0] || {};

    const scenarioData = { scenarioId, scenarioSlug, scenarioTitle };

    const requestMessages: RequestMessage[] = request.dialog.messages.map(message => ({
      id: generateId(),
      chatId: dialog.chat_id,
      metaId: dialog.id,
      recordId: record.id,
      author: message.author,
      text: message.text,
      features: request.features,
      createdAt: record.created_at ?
        dateMscToLocal(new Date(record.created_at)).toString() :
        record.created_at,
      scenarioData,
      attachments: message.attachments?.map(
        item => objSnakeToCamelDeep<Attachment>(item),
      ),
    }));
    accum = accum.concat(requestMessages);

    if (!record.is_hidden) {
      const responseMessage: ResponseMessage = {
        id: generateId(),
        chatId: dialog.chat_id,
        metaId: dialog.id,
        debugId: response.debug_id,
        recordId: record.id,
        author: MessageAuthor.Ai,
        texts: response.reply?.texts,
        textType: response.reply?.texts_types?.includes(TextType.macro) ?
          TextType.macro :
          TextType.text,
        attachments: response.reply?.attachments?.map(item => (
          objSnakeToCamelDeep<Attachment>(item)
        )),
        meta: {
          time: response.defer?.time_sec,
          closed: "close" in response,
          operator: "operator" in response,
          line: response.forward?.line,
          tags: response.tag?.add,
        },
        resolution: {
          status: {
            pending: false,
          },
          comment: {
            value: record.mark_comment,
            pending: false,
          },
        },
        iterationNumber: response.iteration_number,
        responseFeatures: response.features?.features,
        requestFeatures: request.features,
        converged: !!(response.reply || response.defer || response.close),
        probabilities: response.features?.probabilities
          .map(bProbability => parseTopicProbability(bProbability))
          .sort((featureA, featureB) => featureB.probability - featureA.probability),
        createdAt: record.created_at ?
          dateMscToLocal(new Date(record.created_at)).toString() :
          record.created_at,
        isSent: record.is_sent,
        ...(response.explanation && {
          explanation: parseResponseExplanation(response.explanation),
        }),
        scenarioData,
        scenarioPath: scenarioPath && scenarioPath.map(item => objSnakeToCamelDeep<ScenariosWayPoint>(item)),
        ...(response.buttons_block && { buttons: parseDialogButtons(response.buttons_block) }),
        ...(response.scenarios_path?.metric && { metric: parseResponseMetric(response.scenarios_path.metric) }),
      };

      accum.push(responseMessage);
    }

    return accum;
  }, []);

  return {
    id: dialog.chat_id,
    externalId: dialog.chat_user,
    createdAt: dateMscToLocal(new Date(dialog.created_at)).toString(),
    messages,
    replied: dialog.replied,
    scenarioSlug: dialog.scenario_slug,
    resolution: {
      value: dialog.sampling_resolution?.value,
      messageId: dialog.sampling_resolution?.context_id,
    },
    viewed: false,
    sessionId: "",
  };
}

export function parseNewDialog(dialog: NewExtendedDialogBackend): Dialog {
  const hasHiddenRecords = dialog.records.some(r => r.is_hidden);

  const messages = dialog.records.reduce<DialogMessage[]>((accum, record) => {
    const { request, response } = record;

    const scenarioPath = response.scenarios_path;

    const {
      id: scenarioId = '',
      slug: scenarioSlug = '',
      title: scenarioTitle = '',
    } = scenarioPath?.[0]?.scenario || {};

    const scenarioData = { scenarioId, scenarioSlug, scenarioTitle };

    if (!hasHiddenRecords || (hasHiddenRecords && record.is_hidden)) {
      const requestMessages: RequestMessage = {
        id: generateId(),
        chatId: dialog.chat_id,
        author: request.author,
        text: request.text || '',
        features: request.features,
        createdAt: record.created_at ?
          dateMscToLocal(new Date(record.created_at)).toString() :
          record.created_at,
        scenarioData,
      };
      accum = accum.concat(requestMessages);
    }

    if (!record.is_hidden) {
      const responseMessage: ResponseMessage = {
        id: generateId(),
        chatId: dialog.chat_id,
        debugId: response.debug_id,
        author: response.operator ? MessageAuthor.Support : MessageAuthor.Ai,
        texts: response.texts,
        meta: {
          closed: !!response.close,
          operator: !!response.operator,
          line: response.forward,
          tags: response.tags,
        },
        responseFeatures: response.features,
        requestFeatures: request.features,
        createdAt: record.created_at ?
          dateMscToLocal(new Date(record.created_at)).toString() :
          record.created_at,
        isSent: record.is_sent,
        scenarioData,
        scenarioPath: scenarioPath && parseScenarioPath(scenarioPath, response.error?.node_id),
        ...(response.scenarios_path_metric && { metric: parseResponseMetric(response.scenarios_path_metric) }),
        ...(response.buttons && { buttons: response.buttons.map(parseButton) }),
        ...(response.error && { error: objSnakeToCamelDeep(response.error) }),
        version: record.version,
        saiRequestId: record.sai_request_id,
      };

      accum.push(responseMessage);
    }

    return accum;
  }, []);

  return {
    id: dialog.chat_id,
    externalId: dialog.chat_user,
    createdAt: dialog.created_at ?
      dateMscToLocal(new Date(dialog.created_at)).toString() :
      dialog.created_at || '',
    messages,
    replied: "replied_at" in dialog.records[0],
    scenarioTitle: dialog.records[0].response.scenarios_path?.[0]?.scenario?.title,
    scenarioNode: dialog.records[0].response.scenarios_path?.[0]?.node,
    viewed: false,
    tags: dialog.tags_count,
    iterations: dialog.iterations,
    scenariosList: dialog.scenarios_path.map(el => el.title || ''),
    favoriteFeatures: dialog.favorite_features,
    sessionId: dialog.session_id,
    resolution: {
      value: dialog.resolution?.resolution_type_id,
      messageId: dialog.resolution?.sai_request_id,
    },
    ...(dialog.errors_types?.length && { errorsTypes: dialog.errors_types.map(err => err.type) }),
  };
}

function parseTopicProbability(probability: TopicProbabilityBackend): TopicProbability {
  return {
    probability: Math.round(probability.probability * 10000) / 10000,
    topicName: probability.topic_name,
  };
}

function parseResponseExplanation(value: ResponseExplanationBackend): ResponseExplanation {
  return {
    topicExplanation: value.topic_explanation,
    topicTitle: value.topic_title,
    policyTitles: value.policy_titles,
    policyExplanation: value.policy_explanation,
  };
}

export function parseDialogButtons(buttonsBlock: ResponseButtonsBlockBackend | undefined)
: ResponseButtonsBlock | undefined {
  if (!buttonsBlock?.buttons) return undefined;

  return buttonsBlock?.buttons.map(button => ({ ...button, id: generateId() }));
}

export function parseScenarioPath(scenarioPath: NewSupportResponseBackend['scenarios_path'], errorNodeId?: string)
: ScenariosWayPoint[] | undefined {
  return scenarioPath?.map(scPath => ({
    scenarioId: scPath.scenario?.id || '',
    scenarioSlug: scPath.scenario?.slug || '',
    scenarioTitle: scPath.scenario?.title || '',
    nodeId: scPath.node?.id || '',
    nodeTitle: scPath.node?.title || '',
    nodeType: scPath.node?.type || '',
    ...(scPath.metric && { metric: parseResponseMetric(scPath.metric) }),
    ...(errorNodeId === scPath.node?.id && { withError: true }),
  }));
}

export function parseButton(value: string): Button {
  return {
    id: generateId(),
    text: value,
  };
}

export function parseResponseMetric(
  value: ResponseMetricBackend,
): ResponseMetric {
  return {
    totalTime: value.total_time,
  };
}
