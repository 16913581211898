/* eslint-disable prefer-template */
export const AUTHORIZED_ROOT = '/';

const authorizedPages = {
  account: AUTHORIZED_ROOT + 'account',
  features: AUTHORIZED_ROOT + 'features',
  topics: AUTHORIZED_ROOT + 'topics',
  tresholds: AUTHORIZED_ROOT + 'tresholds',
  newDialogsHistory: AUTHORIZED_ROOT + 'new-dialogs-history',
  voice: AUTHORIZED_ROOT + 'voice',
  voiceDetails: AUTHORIZED_ROOT + 'voice/:id',
  accessesNew: AUTHORIZED_ROOT + 'accesses-new',
  additional: AUTHORIZED_ROOT + 'additional',
  changeHistory: AUTHORIZED_ROOT + 'history-changes',
  authProviders: AUTHORIZED_ROOT + 'auth-providers',
  manageVersions: AUTHORIZED_ROOT + 'manage-versions',
  entities: AUTHORIZED_ROOT + 'entities',
  editor: AUTHORIZED_ROOT + 'editor',
  graphSelect: AUTHORIZED_ROOT + 'graph-select',
  projectsList: AUTHORIZED_ROOT + 'projects-list',
  superusers: AUTHORIZED_ROOT + 'superusers',
  integrations: AUTHORIZED_ROOT + 'integrations',
  widgetPanel: AUTHORIZED_ROOT + 'widget-panel',
  // billing: AUTHORIZED_ROOT + 'billing',
  qualityControl: AUTHORIZED_ROOT + 'quality-control',
  tasks: AUTHORIZED_ROOT + 'tasks',
  dialogsHistory: AUTHORIZED_ROOT + 'dialogs-history',
  automationRise: AUTHORIZED_ROOT + 'automation-rise',
};

export function getPagePath(key: string): string {
  return key.slice(AUTHORIZED_ROOT.length);
}

export default authorizedPages;
